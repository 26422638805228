import React from "react"

export default function Header() {


  return (
    <>
      <div id="png-header">

        <div className="png-header__nav">
          <div className="container">
            <a href="/" className="header-logo-link">
              <img src="/images/FirmataNaIvo.png" alt="Logo" />
            </a>
            <section className="page-section page-section--contact">
            <div className="contact-list-container">
              <ul className="contact-list">
                <li>
                  <svg className="mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-check</title><path d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" /></svg>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-check</title><path d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" /></svg>
                    Имейл:
                  </span>
                  <b>valetoltd@gmail.com</b>
                
                </li>
                
                <li>
                    <svg className="mobile" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>phone</title><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>phone</title><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
                    Телефон:
                  </span>
                  <b>089-906-1656</b>
                </li>
              </ul>
            </div>
          </section>
          </div>
          
        </div>

        <section
          className="png-header__parallax"
          style={{
            backgroundImage:
              "url(https://i.imgur.com/IF7oZy3.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="png-header__parallax__inner">
            <h1>
              <div className="red">Експертно</div> оправяне и инсталиране:
            </h1>
            <br />
              <h1>
                {/* Мазилки и шпакловки, свързани топло-изолации, бани, плочки,
                кухни, водопровод и канализация, парни системи,
                газови инсталации, газови котлета. */}
                Фаянс и бани, кухни, подови настилки, шпакловки, мазилки,
                водопроводни инсталации, парни инсталации, канализации, отпушване
                на канализации, и топло изолации.

              </h1>
          </div>
        </section>

      </div>
    </>
  )
}



