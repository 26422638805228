import React, { useRef, useState } from 'react';
// import React, { useRef, useState, useEffect } from 'react';

export default function Gallery(props) {
  let images = props.images
  const imageTrackRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    // Set active state and initial positions
    const imageTrack = imageTrackRef.current;
    setIsActive(true);
    setStartX(e.pageX - imageTrack.offsetLeft);
    setScrollLeft(imageTrack.scrollLeft);
  };

  const handleMouseLeave = () => {
    // Reset active state
    setIsActive(false);
  };

  const handleMouseUp = () => {
    // Reset active state
    setIsActive(false);
  };

  const handleMouseMove = (e) => {
    if (!isActive) return;
    e.preventDefault();
    const imageTrack = imageTrackRef.current;
    const x = e.pageX - imageTrack.offsetLeft;
    const walk = (x - startX); // scroll-fast
    imageTrack.scrollLeft = scrollLeft - walk;

    // Infinite logic here?
  };

  return (
    <>
      <div className="gallery">
        <div
          id="image-track"
          ref={imageTrackRef}
          className={`image-track ${isActive ? 'active' : ''}`}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {images.map((src, index) => (
            <img key={index} loading='lazy' className="image" alt="" src={src} draggable="false" />
          ))}
        </div>
      </div>
    </>
  );
}
