import React, { useState, useEffect } from 'react';

export default function Services() {
  const [selectedSection, handleSelectedSection] = useState('');

  useEffect(() => {
    handleServiceIcon();
  })

  function handleSection (serviceName) {
    if (serviceName === selectedSection){
      handleSelectedSection('');
    } else {
      handleSelectedSection(serviceName);
    }
  }

  function handleServiceIcon(){
    const serviceEls = document.querySelectorAll(".service");
    if (serviceEls.length > 0){
      serviceEls.forEach((serviceEl, index) => {
        const serviceElName = serviceEl.querySelector('.service__name').textContent;
        const serviceIconEl = serviceEl.querySelector('.service__icon');
        let serviceIcon;
        if (serviceElName === services[index].serviceName){
          serviceIcon = services[index].serviceIcon;
          serviceIconEl.innerHTML = serviceIcon;
        }
      })
    }
  }
  const services = [
  // {
  //   serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6 2C4.89 2 4 2.89 4 4V16C4 17.11 4.89 18 6 18H7V20H4V22H7C8.11 22 9 21.11 9 20V18H11V20C11 21.11 11.89 22 13 22H16V20H13V18H14C15.11 18 16 17.11 16 16V4C16 2.89 15.11 2 14 2H6M10 4.97C11.11 4.97 12 5.87 12 6.97S11.11 8.97 10 8.97C8.9 8.97 8 8.07 8 6.97C8 5.87 8.9 4.97 10 4.97M8 14.5H12V16H8V14.5M18 15H20V17H18V15M18 7H20V13H18V7Z" /></svg>`,
  //   serviceName: "Оправяме бойлери",
  //   serviceDescription: "Нашият опитен екип предлага широка гама от услуги за котлони, включително инсталация, ремонти и поддръжка. Ние можем да ви помогнем да изберете най-подходящия котел за вашия дом и предлагаме разнообразие от достъпни и ефективни опции, отговарящи на вашите нужди."
  // },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>fridge</title><path d="M7,2H17A2,2 0 0,1 19,4V9H5V4A2,2 0 0,1 7,2M19,19A2,2 0 0,1 17,21V22H15V21H9V22H7V21A2,2 0 0,1 5,19V10H19V19M8,5V7H10V5H8M8,12V15H10V12H8Z" /></svg>`,
    serviceName: "Ремонт и обновление на кухни",
    serviceDescription: "Нашите специалисти предлагат комплексни услуги за ремонт и обновление на кухни, включително инсталиране на нови шкафове, плотове, уреди и осветление. С нашата помощ, можете да преобразите кухнята си в удобно и стилно пространство, съобразено с вашите нужди и предпочитания."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>floor-plan</title><path d="M10,5V10H9V5H5V13H9V12H10V17H9V14H5V19H12V17H13V19H19V17H21V21H3V3H21V15H19V10H13V15H12V9H19V5H10Z" /></svg>`,
    serviceName: "Фаянс и теракотa",
    serviceDescription: "Ние предлагаме професионални услуги за полагане и ремонт на фаянс и теракот. Нашите експерти са опитни в инсталирането на плочки на подове и стени, както и в извършването на ремонти и реставрации на съществуващи плочки. Осигуряваме високо качество на работа и внимание към детайлите."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>shower</title><path d="M21,14V15C21,16.91 19.93,18.57 18.35,19.41L19,22H17L16.5,20C16.33,20 16.17,20 16,20H8C7.83,20 7.67,20 7.5,20L7,22H5L5.65,19.41C4.07,18.57 3,16.91 3,15V14H2V12H20V5A1,1 0 0,0 19,4C18.5,4 18.12,4.34 18,4.79C18.63,5.33 19,6.13 19,7H13A3,3 0 0,1 16,4C16.06,4 16.11,4 16.17,4C16.58,2.84 17.69,2 19,2A3,3 0 0,1 22,5V14H21V14M19,14H5V15A3,3 0 0,0 8,18H16A3,3 0 0,0 19,15V14Z" /></svg>`,
    serviceName: "Ремонт на бани",
    serviceDescription: "Нашият екип предлага пълни услуги за ремонт на бани, включително инсталиране на нови санитарни уреди, плочки, и осветление. Ние се грижим за всичко – от дизайна и планирането до изпълнението, за да създадем функционално и естетично пространство, което да отговаря на вашите желания и нужди."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M22,22H2V20H22V22M22,6H2V3H22V6M20,7V19H17V11C17,11 14.5,10 12,10C9.5,10 7,11 7,11V19H4V7H20M14.5,14.67H14.47L14.81,15.22L14.87,15.34C15.29,16.35 15,17.5 14.21,18.24C13.5,18.9 12.5,19.07 11.58,18.95C10.71,18.84 9.9,18.29 9.45,17.53C9.3,17.3 9.19,17.03 9.13,16.77L9,16.11C8.96,15.15 9.34,14.14 10.06,13.54C9.73,14.26 9.81,15.16 10.3,15.79L10.36,15.87C10.44,15.94 10.55,15.97 10.64,15.92C10.73,15.89 10.8,15.8 10.8,15.7L10.76,15.56C10.23,14.17 10.68,12.55 11.79,11.63C12.1,11.38 12.5,11.15 12.87,11.05C12.46,11.87 12.61,12.93 13.25,13.57L14.14,14.3L14.5,14.67M13.11,17.44V17.44C13.37,17.2 13.53,16.8 13.5,16.44V16.25C13.38,15.65 12.85,15.46 12.5,15L12.26,14.55C12.13,14.85 12.12,15.13 12.17,15.46C12.23,15.8 12.37,16.09 12.29,16.44C12.2,16.83 11.9,17.22 11.37,17.35C11.67,17.64 12.15,17.87 12.64,17.71L13.11,17.44Z" /></svg>`,
    serviceName: "Парни системи",
    serviceDescription: "Разбираме важността да осигурим безопасността на вас или вашите наематели, и нашият екип предлага изчерпателни проверки на парните системи, за да гарантираме, че вашето имущество отговаря на всички необходими стандарти за безопасност. Ние извършваме проверки на парната система, които включват проверка на работоспособността, сигурността на вентилите и котлите, както и измерване на налягането и температурата, за да осигурим безопасно и ефективно функциониране на системата."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.95,3L6.53,5.19L7.95,7.4H7.94L5.95,10.5L4.22,9.6L5.64,7.39L4.22,5.19L6.22,2.09L7.95,3M13.95,2.89L12.53,5.1L13.95,7.3L13.94,7.31L11.95,10.4L10.22,9.5L11.64,7.3L10.22,5.1L12.22,2L13.95,2.89M20,2.89L18.56,5.1L20,7.3V7.31L18,10.4L16.25,9.5L17.67,7.3L16.25,5.1L18.25,2L20,2.89M2,22V14A2,2 0 0,1 4,12H20A2,2 0 0,1 22,14V22H20V20H4V22H2M6,14A1,1 0 0,0 5,15V17A1,1 0 0,0 6,18A1,1 0 0,0 7,17V15A1,1 0 0,0 6,14M10,14A1,1 0 0,0 9,15V17A1,1 0 0,0 10,18A1,1 0 0,0 11,17V15A1,1 0 0,0 10,14M14,14A1,1 0 0,0 13,15V17A1,1 0 0,0 14,18A1,1 0 0,0 15,17V15A1,1 0 0,0 14,14M18,14A1,1 0 0,0 17,15V17A1,1 0 0,0 18,18A1,1 0 0,0 19,17V15A1,1 0 0,0 18,14Z" /></svg>`,
    serviceName: "Свързани топло-изолации",
    serviceDescription: "В Валето ЕООД разбираме важността на надеждни и ефективни отоплителни системи в дома или бизнеса ви. Нашият екип може да ви помогне да модернизирате радиаторите и тръбите си, осигурявайки, че системата ви работи с максимална ефективност и ви спестява пари за енергия."
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 14V16H16V14.28L19 14M19 13C19 11.9 18 11 16.8 11H10V10H5V21H10V13.91L19 13M5 9H10V7L15.36 5.21C15.74 5.09 16 4.73 16 4.33C16 3.68 15.36 3.23 14.75 3.45L5 7V9Z" /></svg>`,
    serviceName: "Водопровод и канализация",
    serviceDescription: "В Валето ЕООД ние специализираме в ремонт и поддръжка на водопроводни и канализационни системи. От отстраняване на течове до инсталиране на нови тръби и уреди, нашите опитни техници осигуряват ефективни решения за вашите нужди. Ние гарантираме качествена работа и дълготрайни резултати.",
  },
  {
    serviceIcon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 4A4 4 0 0 1 14 8A4 4 0 0 1 10 12A4 4 0 0 1 6 8A4 4 0 0 1 10 4M10 14C14.42 14 18 15.79 18 18V20H2V18C2 15.79 5.58 14 10 14M20 12V7H22V13H20M20 17V15H22V17H20Z" /></svg>`,
    serviceName: "Основни ремонти",
    serviceDescription: "Ние разбираме важността да поддържате безопасността на вашите наематели, и нашият екип предлага комплексни проверки за безопасност за домакини, за да се уверите, че вашето имущество отговаря на всички необходими стандарти за безопасност. Ние извършваме проверки на газова инсталация, проверки на котлони и други, за да ви помогнем да гарантирате безопасността и сигурността на вашите наематели."
  }
]
  return (
    <>
      <section className="page-section page-section--services">
          <div className="services-container">
            {
              services.map(service => (
                <div 
                  className={selectedSection === service.serviceName ? 'service service--active' : 'service'} 
                  key={service.serviceName} 
                  onClick={() => handleSection(service.serviceName)}
                >
                  <div className="service__top">
                    <div className="service__icon"></div>
                    <div className="service__name">
                      {service.serviceName}
                      <svg className="service__chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                    </div>
                  </div>
                  <div className="service__description">{service.serviceDescription}</div>
                  
                </div>
              ))
            }
          </div>
        </section>
    </>
  );
}
