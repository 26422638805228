// import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import React from "react"


export default function App() {
  return (
    <>
      <Header />
      <div className="png-page">
        {/* <Sidebar /> */}
          <Home />
      </div>
      <Footer />
    </>
  )
}
