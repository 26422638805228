import React from 'react';
import Gallery from './Gallery'
import Services from './Services'

export default function Home () {


  const imagesKitchen = [
    "https://i.imgur.com/PBEc0cs.jpeg",
    "https://i.imgur.com/nGizLGd.jpeg",
    "https://i.imgur.com/2R1tA2l.jpeg",
    "https://i.imgur.com/o1MwhxG.jpeg",
    "https://i.imgur.com/mrDyQHV.jpeg",
    "https://i.imgur.com/Ar2J6nQ.jpeg",
    "https://i.imgur.com/QrriXd0.jpeg",
    "https://i.imgur.com/WZ8uC0V.jpeg",
    "https://i.imgur.com/3vNkMiF.jpeg",
    "https://i.imgur.com/cFUrDYW.jpeg"
  ];

  const imagesBaths = [
    "https://i.imgur.com/n3IkdPJ.jpeg",
    "https://i.imgur.com/5V30FYg.jpeg",
    "https://i.imgur.com/AlCtTHr.jpeg",
    "https://i.imgur.com/S2YGOD4.jpeg",
    "https://i.imgur.com/qofQyj2.jpeg",
    "https://i.imgur.com/qzwMCR9.jpeg",
    "https://i.imgur.com/134Izpk.jpeg",
    "https://i.imgur.com/cb0p4hD.jpeg",
    "https://i.imgur.com/Y7Bx5kw.jpg"
  ];
  
  const imagesDesign = [
    "https://i.imgur.com/uWLxooA.jpeg",
    "https://i.imgur.com/lHewTtb.jpg",
    "https://i.imgur.com/kuJ2lE4.jpg",
    "https://i.imgur.com/52Nsz3q.jpg",
    "https://i.imgur.com/OOqZ73D.jpeg",
    "https://i.imgur.com/Kk4FzCS.jpg",
    "https://i.imgur.com/61bPZTj.jpg",
    "https://i.imgur.com/eWzbLoq.jpeg",
    "https://i.imgur.com/uWLxooA.jpeg",
    "https://i.imgur.com/Qxr5gAI.jpeg",
  ];

  return (
    <div className="page page--home">
      {/* Свържете се с нас */}
      <div className="container">
        <section className="page-section page-section--contact">
          <div className="h2">Свържете се с нас:</div>
          <div className="contact-list-container">
            <ul className="contact-list">
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>email-check</title><path d="M13 19C13 15.69 15.69 13 19 13C20.1 13 21.12 13.3 22 13.81V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V18C2 19.11 2.9 20 4 20H13.09C13.04 19.67 13 19.34 13 19M4 8V6L12 11L20 6V8L12 13L4 8M17.75 22.16L15 19.16L16.16 18L17.75 19.59L21.34 16L22.5 17.41L17.75 22.16" /></svg>
                  Имейл:
                </span>
                <b>valetoltd@gmail.com</b>
              </li>
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>phone</title><path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" /></svg>
                  Телефон:
                </span>
                <b>089-906-1656</b>
              </li>
            </ul>
          </div>
        </section>
      </div>

      {/* Галерия 1 */}
      <div className="container container--gallery container--wide">
        <section className="page-section page-section--reviews">
          <div className="h2">Бани</div>
          
          <Gallery images={imagesBaths}/>
        </section>
      </div>

      {/* Кои сме ние */}
      <div className="container">
        <section className="page-section page-section--introduction">
          <div className="h2">Валето ЕООД - Екип на експерти в майсторството.</div>
          <p>
          Ние сме екип от висококвалифицирани експерти с дългогодишен опит в строителството и ремонта. 
          Специализираме в различни области, включително фаянс и бани, кухни, подови настилки, шпакловки и мазилки, водопроводни инсталации, парни инсталации, канализации и отпушване, и топло изолации. 
          {/* мазилки и шпакловки, топлоизолации, бани, плочки, кухни, 
          водопровод и канализация, парни системи, газови инсталации и газови котлета. */}
          Нашият подход е да предлагаме цялостни решения, 
          съобразени с индивидуалните нужди на клиентите ни. 
          Стремим се към високо качество и перфекционизъм във всяка стъпка от процеса, използвайки само най-добрите материали и модерни техники. 
          Доверете ни се за вашите ремонтни и строителни проекти и ние ще ги превърнем в реалност!
          </p>
        </section>
      </div>

      {/* Галерия 2 */}
      <div className="container container--gallery container--wide">
        <section className="page-section page-section--reviews">
          <div className="h2">Кухни</div>
          <Gallery images={imagesKitchen}/>
        </section>
      </div>

      {/* Работно Време */}
      <div className="container">
        <section className="page-section page-section--work-hours">
          <div className="h2">Работно време</div>
          <div className="work-hours-list-container">
            <ul className="work-hours-list">
              <li>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>calendar-weekend-outline</title><path d="M19 3C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H6V1H8V3H16V1H18V3H19M19 19V9H5V19H19M19 7V5H5V7H19M7 11H9V17H7V11M15 11H17V17H15V11Z" /></svg>
                  Понеделник-Петък
                </span>
                <b>8:00-20:00</b>
              </li>
              <li>
                <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>calendar-weekend-outline</title><path d="M19 3C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3H6V1H8V3H16V1H18V3H19M19 19V9H5V19H19M19 7V5H5V7H19M7 11H9V17H7V11M15 11H17V17H15V11Z" /></svg>
                  Събота
                </span>
                <b>9:00-14:00</b>
              </li>
            </ul>
          </div>
          <p>
          В случай на авария, нашият екип е на разположение извън работното време, за да осигури бърза и ефективна помощ. 
          Ние разбираме важността на своевременната реакция и сме готови да отговорим на вашите нужди по всяко време.
          </p>
        </section>
      </div>

      {/* Ревюта и призиви */}
      {/* <div className="container container--wide container--reviews">
        <section className="page-section page-section--reviews">
          <div className="h2">Какво казват нашите клиенти</div>
          <div className="reviews-container">
            {
              reviews.map((review, index) => (
                <div className="review" key={index}>
                  <div className="review-name">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>message-draw</title><path d="M18,14H10.5L12.5,12H18M6,14V11.5L12.88,4.64C13.07,4.45 13.39,4.45 13.59,4.64L15.35,6.41C15.55,6.61 15.55,6.92 15.35,7.12L8.47,14M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" /></svg>
                    {review.name}
                  </div>
                  <div className="review-main">{review.desc}</div>
                </div>
              ))
            }
          </div>
          <div className="reviews-additional">
            <a href="https://www.google.com/search?q=FirmataNaIvo&oq=FirmataNaIvo&aqs=chrome.0.69i59l2j69i61l3.3991j0j15&sourceid=chrome&ie=UTF-8#lrd=0xa70bdd202ee18e81:0xe510d9db8a6bf074,1,,,," className="reviews-additional-link" target="_blank" rel="noreferrer">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>plus-thick</title><path d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" /></svg>
              <span>Вижте още</span>
            </a>
          </div>
        </section>
      </div> */}

      {/* Галерия 3 */}
      <div className="container container--gallery">
        <section className="page-section page-section--reviews">
          <div className="h2">Водни Системи и Парно отопление</div>
          <Gallery images={imagesDesign}/>
        </section>
      </div>


      {/* Ако иска менютата като старите */}
      <div className="container container--wide">
        <Services />
      </div>
    </div>
  )
}

// const reviews = [
//   {
//     name: "Стоян",
//     desc: "Това е най-добрият екип. Добре и бързо свършена работа.",
//   },
//   {
//     name: "Станислав",
//     desc: "Момчетата си свършиха работата страхотно върху новата ми баня.",
//   },
//   {
//     name: "Тони Стораро",
//     desc: "Най-добрата фирма, най-добрата фирма вашата е."
//   }
// ]



